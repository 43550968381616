<template>
  <div :style="CSS + ';display: inline-block;height:' + height + ';width:' + width"  v-show="!isHidden">
    <el-image
      :src="urlImage"
      :fit="fit">
      <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
export default {
  name: 'a-image',
  mixins: [mixin, VisibleMixin],
  props: {
    fit: {
      type: String,
      editor: 'ImageFit',
      description: 'Заполнение'
    },
    url: {
      type: String,
      editor: 'Link',
      description: 'Ссылка'
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    height: {
      type: String,
      description: 'Высота'
    }
  },
  computed: {
    urlImage () {
      if (this.url) {
        if (this.url.startsWith('http')) {
          return this.url
        } else {
          return `${this.$config.api}${this.url}`
        }
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>
